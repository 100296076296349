<template>
  <SchoolLeaveEntitlementTable
    :items="items"
    :is-busy="isBusy"
    :pagination="pagination"
    @navigateToIndividual="goToStaffAssignment"
  >
  </SchoolLeaveEntitlementTable>
</template>

<script>
import SchoolLeaveEntitlementTable from "@/modules/school/components/management/leave/entitlement/SchoolLeaveEntitlementTable";
import { mapGetters } from "vuex";
export default {
  name: "StaffLeaveAssignment",
  components: { SchoolLeaveEntitlementTable },
  data() {
    return {
      isBusy: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    /**
     * Fetch Staff list from store
     */
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchStaffList")
        .finally(() => (this.isBusy = false));
    },

    /**
     * Proceed to Staff Individual Entitlement Page
     *
     * @param data
     */
    goToStaffAssignment(data) {
      console.log(data);
      this.$router.push({
        name: "staffAssignmentPage",
        query: { id: data.item.id, name: data.item.full_name },
      });
    },
  },
  computed: {
    ...mapGetters({
      items: "getStaffList",
      pagination: "getStaffListPagination",
    }),
  },
};
</script>

<style scoped></style>
