<template>
  <div>
    <b-table
      table-class="table-head-custom table-vertical-center"
      show-empty
      :fields="fields"
      :items="items"
      :busy="isBusy"
      hover
      :responsive="true"
      @row-clicked="$emit('row-clicked', $event)"
    >
      <!-- begin: t-head -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end: t-head -->

      <!-- begin: t-row -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(full_name)="data">
        <CommonEmployeeSummary :item="data.item"></CommonEmployeeSummary>
      </template>

      <template v-slot:cell(email)="data">
        {{ data.item.email }}
      </template>

      <!--  begin:: actions    -->
      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-employee button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="$emit('navigateToIndividual', data)"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>
          <!-- end::: edit-employee button -->
          <!-- end::: delete-employee button -->
        </div>
      </template>
      <!--  end:: actions    -->

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          text-top="No staffs registered"
          text-bottom="To register staff, click at the CREATE button"
        ></AppEmptyList>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        pills
        size="lg"
        align="right"
        class="pt-5"
        @change="$emit('onPageChange', $event)"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="employee-page-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
import AppLoader from "@/modules/core/components/loaders/AppLoader";

export default {
  name: "SchoolLeaveEntitlementTable",
  components: { AppLoader, AppEmptyList, CommonEmployeeSummary },
  props: {
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    pagination: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      fields: [
        { key: "index", label: "#" },
        { key: "full_name", label: this.$t("EDULEAVE.NAME"), sortable: true },
        { key: "actions", label: this.$t("EDUTABLE.ACTIONS") },
      ],
    };
  },
};
</script>

<style scoped></style>
